<template>
  <div class="contact-us">
    <div class="container">
      <div class="row">
        <div class="contact-us__title">
          <span>Contact Us</span>
        </div>
        <div class="contact-us__form">
          <div class="row">
            <div class="input-block col-md-4">
              <input type="text" v-model="$v.form.name.$model"><span/>
              <span class="input-block__name" :class="form.name.length ?'active' : null" >Your Name</span>
              <small class="input-block__error" v-if="$v.form.name.$dirty && $v.form.name.$error">The name must be at least 2 characters</small>
            </div>
            <div class="input-block col-md-4">
              <input type="text" v-model="$v.form.email.$model"><span/>
              <span class="input-block__name" :class="form.email.length ?'active' : null" >E-mail</span>
              <small class="input-block__error" v-if="$v.form.email.$dirty && !$v.form.email.required">
                email is required
              </small>
              <small class="input-block__error" v-if="!$v.form.email.email">
                email is required
              </small>
            </div>
            <div class="input-block col-md-4">
              <input type="text" v-mask="'+###############'" v-model="$v.form.phone.$model"><span/>
              <span class="input-block__name" :class="form.phone.length ?'active' : null" >Phone number</span>
              <small class="input-block__error" v-if="$v.form.phone.$dirty && !$v.form.phone.required">phone is required</small>
            </div>
            <div class="input-block col-md-8">
              <input type="text" v-model="$v.form.message.$model"><span/>
              <span class="input-block__name" :class="form.message.length ?'active' : null" >Message</span>
              <small class="input-block__error" v-if="$v.form.message.$dirty && $v.form.message.$error">The message must be at least 5 characters</small>
            </div>
            <div class="col-md-4 d-flex justify-center">
              <button
                  class="btn grey"
                  @click="sendForm"
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapMutations} from "vuex";
import { required, email, minLength } from 'vuelidate/lib/validators'

export default{
  data(){
    return{
      form:{
        name: "",
        email: "",
        phone: "",
        message: "",
      }
    }
  },
  validations: {
    form: {
      name: {
        minLength: minLength(2),
        required,
      },
      email: {
        required,
        email,
      },
      phone: {
        required,
      },
      message: {
        required,
        minLength: minLength(5),
      },
    }
  },
  methods:{
    sendForm(){
      this.$v.$touch();
      if(this.validateForm()){
        this.showForm = false;
        this.sendContactsUs(this.form).then(res => {
          if(res.status){
            this.toggleAlertPopup({
              status: true,
              content:{
                title:'Success!',
                text:'Your message has been successfully delivered. Wait for a reply to the email!',
                type:"success"
              }
            })
          }else{
            this.toggleAlertPopup({
              status: true,
              content:{ title:'Error', text: res.data.errors, type:"error"}
            })
          }
          this.clearForm();
        })
      }
    },
    validateForm(){
      return this.$v.form.$dirty && !this.$v.form.$invalid
    },
    clearForm(){
      this.form = {
        name: "",
        email: "",
        phone: "",
        message: "",
      }
    },
    ...mapActions({
      sendContactsUs: 'landing/sendContactsUs'
    }),
    ...mapMutations({
      toggleAlertPopup: 'general/toggleAlertPopup'
    })
  }
}
</script>
<style lang="scss" scoped>
@import "src/assets/styles/styles";
.contact-us{
  padding: 90px 0 90px;
  position: relative;
  @media screen and (max-width: 720px){
    padding: 40px;
  }
  &:before{
    content: '';
    position: absolute;
    pointer-events: none;
    right: 0;
    top: -20%;
    height: 550px;
    width: 300px;
    max-width: 15%;
    background-image: url("~@/assets/images/contact-bg_2.png");
    background-size: contain;
    background-position: right top;
    background-repeat: no-repeat;
    z-index: 1;
    @media screen and (max-width: 1340px) {
      display: none;
    }
  }
  &:after{
    content: '';
    position: absolute;
    pointer-events: none;
    left: 0%;
    top: -10%;
    height: 400px;
    width: 250px;
    max-width: 12%;
    background-image: url("~@/assets/images/contact-bg_1.png");
    background-size: contain;
    background-position: left top;
    background-repeat: no-repeat;
    z-index: 1;
    @media screen and (max-width: 1340px) {
      display: none;
    }
  }
  &__title{
    font-family: 'Mont', Arial;
    font-size: 96px;
    font-weight: 900;
    color: $grey;
    margin-bottom: 60px;
    @media screen and (max-width: 1220px){
      font-size: 66px;
    }
    @media screen and (max-width: 720px){
      font-size: 46px;
    }
    span{
      position: relative;
      &:after{
        content: '';
        position: absolute;
        left: calc(100% + 20px);
        bottom: 100%;
        background-color: $yellow;
        height: 38px;
        width: 38px;
        border-radius: 50%;
      }
    }
  }
  .input-block{
    margin-bottom: 40px;
  }
}
</style>
